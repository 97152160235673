// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-page-templates-entry-blog-js": () => import("./../../../src/pageTemplates/Entry_blog.js" /* webpackChunkName: "component---src-page-templates-entry-blog-js" */),
  "component---src-page-templates-entry-with-hero-js": () => import("./../../../src/pageTemplates/Entry_withHero.js" /* webpackChunkName: "component---src-page-templates-entry-with-hero-js" */),
  "component---src-page-templates-entry-without-hero-js": () => import("./../../../src/pageTemplates/Entry_withoutHero.js" /* webpackChunkName: "component---src-page-templates-entry-without-hero-js" */),
  "component---src-page-templates-entry-work-js": () => import("./../../../src/pageTemplates/Entry_work.js" /* webpackChunkName: "component---src-page-templates-entry-work-js" */),
  "component---src-pages-components-js": () => import("./../../../src/pages/components.js" /* webpackChunkName: "component---src-pages-components-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

